import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";

import { fetchWritingSets, getWritingSetById } from "src/reducers/writingSetReducer";
import { fetchDocumentAsset } from "src/reducers/documentAssetReducer";
import "./project.scss";

import { WMCard } from "src/components/wmCard";
import { DocumentCard } from "src/components/documentCard";
import { RootState } from "src/configureStore";
import { BeatLoader } from "react-spinners";
import { useAppDispatch } from "src/configureStore";
import { setSelectedDocumentType } from "src/reducers/documentTypeReducer";
import AppealImage from "src/assets/images/appeal.svg";
import ProposalImage from "src/assets/images/proposal.svg";
import ThankYouImage from "src/assets/images/thankYou.svg";
import PersonalImage from "src/assets/images/personalEmail.svg";
import BlankDocImage from "src/assets/images/blankDoc.svg";
import { setSelectedProjectById } from "src/reducers/projectReducer";
// import { createDocumentType } from "../reducers/documentTypeReducer";
import { getAllWritingModels } from "src/reducers/writingSetReducer";
import { fetchCampaignUsage } from "src/reducers/clientReducer";

const Project = () => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const navigate = useNavigate();
  const { projectId, documentAssetId } = useParams();
  const { selectedProject, projects } = useSelector((state: RootState) => state.projectsReducer);
  const { allWritingModels, loadedModels } = useSelector((state: RootState) => state.writingSets);
  useEffect(() => {
    dispatch(fetchWritingSets());

    if (!loadedModels) {
      if (documentAssetId) {
        dispatch(getAllWritingModels(documentAssetId));
      } else {
        dispatch(getAllWritingModels(""));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedProject === undefined && projects.length > 0 && projectId !== undefined) {
      dispatch(setSelectedProjectById(projectId));
    }
  });
  const { writingSets, isLoading } = useSelector((state: RootState) => state.writingSets);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <BeatLoader color={"#333"} loading={isLoading} size={10} />
      </div>
    );
  }

  function selectImage(wModel: string) {
    switch (wModel) {
      case "Grants & Proposals":
        return ProposalImage;
      case "Thank You & Stewardship":
        return ThankYouImage;
      case "Personal Email Outreach":
        return PersonalImage;
      case "Fundraising Appeal":
        return AppealImage;
      default:
        return BlankDocImage;
    }
  }

  return (
    <div className="landingPage container mb-10">
      {selectedProject !== undefined && selectedProject.children.length > 0 && (
        <>
          <div
            className="font-semibold text-2xl pl-10 mt-2 mb-2"
            style={{ display: "flex", height: "30px", alignItems: "center", color: "#575757" }}>
            {selectedProject.name}
          </div>
          <hr style={{ width: "100%" }} />
          <div
            className="pl-10 flex flex-wrap font-semibold text-lg mb-4 mt-4"
            style={{ color: "#575757" }}>
            Continue Writing
          </div>
          <div className="gap-4 flex flex-wrap justify-center m-6">
            {selectedProject?.children.map((item: any, index: any) => {
              {
                console.log(item);
              }
              return (
                <DocumentCard
                  key={index}
                  title={item.name}
                  project={"Project"}
                  image={selectImage(item.writingSet?.name)}
                  color={item.writingSet?.backgroundColor || "#FFFFFF"}
                  borderColor={item.writingSet?.borderColor || "#000000"}
                  textColor={item.writingSet?.textColor || "#000000"}
                  onClick={async () => {
                    // console.log("CLICKING ITEM");
                    // console.log(item);
                    await dispatch(setSelectedDocumentType(item._id));
                    await dispatch(fetchDocumentAsset(item.children[0]._id));
                    navigate(
                      `/project/${item.project}/documentType/${item._id}/documentAsset/${item.children[0]._id}`
                    );

                    // navigate(`/project/${projectId}/writingWizard/${item._id}`);
                  }}
                />
              );
            })}
          </div>
        </>
      )}
      {selectedProject !== undefined && selectedProject.children.length > 0 ? (
        <div
          className="pl-10 flex flex-wrap font-semibold text-lg mb-4 mt-4"
          style={{ color: "#575757" }}>
          Start Writing
        </div>
      ) : (
        <div
          className="pl-10 flex flex-wrap justify-center font-semibold text-lg mb-4 mt-4"
          style={{ color: "#575757" }}>
          What do you want to write?
        </div>
      )}
      <div className="gap-4 flex flex-wrap justify-center">
        {writingSets.map((item: any, index: any) => (
          <WMCard
            key={index}
            title={item.name}
            image={selectImage(item.name)}
            description={item.description}
            color={item.backgroundColor}
            textColor={item.textColor}
            borderColor={item.borderColor}
            onClick={async () => {
              //if the writingSet is a blank document, then we need to create a new documentType that has a documentAsset as a key instead
              //of adding it to the chilren array
              console.log(item);
              if (item.name === "Blank Document") {
                // const models = [{ name: "New Asset", modelId: "" }];
                // if (projectId !== undefined) {
                //   const nDocumentType: any = await dispatch(
                //     createDocumentType(projectId, item._id, {
                //       name: "New Document",
                //       children: models
                //     })
                //   );
                //   console.log(nDocumentType);
                //   navigate(
                //     `/project/${projectId}/documentType/${nDocumentType.documentType._id}/documentAsset/${nDocumentType.documentType.children[0]}`
                //   );
                // }
                // navigate(`/project/${projectId}/writingWizard/blank`);
                dispatch(setSelectedDocumentType(""));
                navigate(`/project/${projectId}/writingWizard/blank`);
                return;
              } else {
                if (item.name === "One-Click Campaign") {
                  dispatch(fetchCampaignUsage());
                }
                dispatch(getWritingSetById(item._id));
                dispatch(setSelectedDocumentType(""));
                navigate(`/project/${projectId}/writingWizard/${item._id}`);
              }
            }}
          />
          // <Grid item xs={12} sm={6} md={4} lg={12} key={index}>
          //   <Card key={index} variant="outlined">
          //     <CardContent sx={{ backgroundColor: item.backgroundColor }}>
          //       <Typography gutterBottom variant="h5" component="div">
          //         {item.modelName}
          //       </Typography>
          //       <CardMedia
          //         component="img"
          //         height="50"
          //         width="50"
          //         image={"https://source.unsplash.com/random"}
          //         alt={item.modelName}
          //       />
          //     </CardContent>
          //     <Divider />
          //     <CardContent sx={{ bgcolor: "white" }}>
          //       <Typography variant="body2" color="text.secondary">
          //         {item.description}
          //       </Typography>
          //     </CardContent>
          //   </Card>
          // </Grid>
        ))}
      </div>
    </div>
  );
};
export default Project;
