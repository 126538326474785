import React, { useState, useRef, useEffect } from "react";
import { ExitToApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { RootState } from "src/configureStore";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";
import "./header.scss";
import { logout } from "src/reducers/authReducer";
import { useAppDispatch } from "src/configureStore";
import { ProfileIcon, HelpIcon, LogoutIcon } from "src/lib/icons/index";
// import LoginToBlackbaud from "./loginToBlackbaud";
import { HtmlTooltip } from "src/components/tooltip";
import useOutsideClick from "src/hooks/useOutsideClick";
import axiosInstance from "src/lib/axiosConfig";
import { UserData } from "src/pages/userProfile";
import StripePricingTable from "src/components/stripePricingTable";
import StripeBuyButton from "./stripeBuyButton";
import { fetchProjects } from "src/reducers/projectReducer";
import { fetchUserRecents } from "src/reducers/clientReducer";
import { updateClient, updateClients } from "src/reducers/authReducer";

Modal.setAppElement("#root");
declare module "@mui/material/styles" {
  interface Palette {
    purple: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    purple?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    purple: true;
  }
}

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState("loading");
  const initials = `${user?.firstName[0]}${user?.lastName[0]}`.toUpperCase();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [pricingModalIsOpen, setPricingModalIsOpen] = useState(false);
  const [pricingModelBlackbaudModalIsOpen, setPricingModelBlackbaudModalIsOpen] = useState(false);
  const [credits, setCredits] = useState(0);
  const [spentCredits, setSpentCredits] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isCreditBtnVisible, setIsCreditBtnVisible] = useState(false);
  const percentSpent = Math.min(Math.floor((spentCredits / credits) * 100), 100);
  const percentLeft = 100 - percentSpent;

  const [userData, setUserData] = useState<UserData>({
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    jobRole: user?.jobRole || "",
    industry: user?.industry || "",
    _id: user?._id || ""
  });
  useEffect(() => {
    setUserData({
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      jobRole: user?.jobRole || "",
      industry: user?.industry || "",
      _id: user?._id || ""
    });
  }, [user]);
  useEffect(() => {
    async function runCheck() {
      const userUrl = process.env["REACT_APP_API_SERVER"] + "/api/getUserClients";
      const userResponse = await axiosInstance.get(userUrl, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      if (userResponse.status === 200) {
        const clients = userResponse.data.clients;
        dispatch(updateClients(clients));
      }
      const url = process.env["REACT_APP_API_SERVER"] + "/api/checkSubscription";

      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      if (response.status === 200 && response.data.isSubscribed) {
        setHasSubscription(true);
        setSubscriptionLevel(response.data.level);
        setCredits(response.data.credits);
        setSpentCredits(response.data.spentCredits);
        // console.log("SUSCRIPTION LEVEL: ", response.data.level);
        return true;
      } else {
        setHasSubscription(false);
        setSubscriptionLevel(response.data.level);
        setCredits(response.data.credits);
        setSpentCredits(response.data.spentCredits);
        // console.log("SUSCRIPTION LEVEL: ", response.data.level);
        return false;
      }
    }

    runCheck();
  }, []);

  useEffect(() => {
    if (percentSpent >= 80 && !subscriptionLevel.includes("pro")) {
      setIsCreditBtnVisible(true);
    }
  }, [percentSpent]);

  useOutsideClick(menuRef, () => {
    if (profileMenuOpen) {
      setProfileMenuOpen(false);
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setProfileMenuOpen(!profileMenuOpen);
  };

  const navHome = () => {
    navigate("/");
  };
  const addCredits = async () => {
    if (hasSubscription) {
      // console.log("has subscription");
      // const url = process.env["REACT_APP_API_SERVER"] + "/api/getStripeBillingPortalSession";

      // const response: any = await axiosInstance.get(url, {
      //   headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      // });
      // console.log(response);
      // if (response.status === 200 && response.data.url) {

      const url = `https://buy.stripe.com/${process.env["STRIPE_BLACKBAUD_ADD_CREDITS_ID"]}?prefilled_email=${userData.email}&client_reference_id=${userData._id}`;
      window.open(url, "_blank");
      // }
    } else {
      // console.log(userData);
      // setPricingModalIsOpen(true);
    }
  };
  const upgradePlan = async () => {
    if (hasSubscription) {
      // console.log("has subscription");
      const url = process.env["REACT_APP_API_SERVER"] + "/api/getStripeBillingPortalSession";

      const response: any = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      // console.log(response);
      if (response.status === 200 && response.data.url) {
        window.open(response.data.url);
      }
    } else {
      // console.log(userData);
      setPricingModalIsOpen(true);
    }
  };
  const closeModal = () => {
    setPricingModalIsOpen(false);
  };
  // console.log("USER DATA &&&&");
  // console.log(userData);
  return (
    <div
      className="flex flex-row justify-between top-0 app-bar w-full z-10 flex-wrap"
      style={{
        padding: "5px",
        display: "sticky",
        borderBottom: "1px solid rgb(235,235,235)",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.1)"
      }}>
      <Modal
        isOpen={pricingModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Pricing Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            height: "550px",
            margin: "auto",
            padding: "20px",
            border: "1px solid rgb(0, 17, 46)",
            borderRadius: "4px",
            backgroundColor: "rgb(0, 17, 46)",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <StripePricingTable
          pricingTableId={process.env["STRIPE_PRICING_TABLE_ID"]}
          email={userData.email}
          customerId={userData._id}
        />
        <div
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            setPricingModalIsOpen(false);
            setPricingModelBlackbaudModalIsOpen(true);
          }}>
          Switch To Blackbaud Pricing
        </div>
      </Modal>
      <Modal
        isOpen={pricingModelBlackbaudModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Pricing Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            height: "550px",
            margin: "auto",
            padding: "20px",
            border: "1px solid rgb(0, 17, 46)",
            borderRadius: "4px",
            backgroundColor: "rgb(0, 17, 46)",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <StripePricingTable
          pricingTableId={process.env["STRIPE_BLACKBAUD_PRICING_TABLE_ID"]}
          email={userData.email}
          customerId={userData._id}
        />
        <div
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            setPricingModalIsOpen(true);
            setPricingModelBlackbaudModalIsOpen(false);
          }}>
          Switch To Base Pricing
        </div>
      </Modal>
      <img
        src={fundwriterLogo}
        alt="Logo"
        className="logo"
        onClick={navHome}
        style={{ cursor: "pointer" }}
      />
      <div className="spacer" />
      {isCreditBtnVisible ? (
        <div className="text-sm mr-4" style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <div
            className="flex items-center justify-between bg-gray-200 rounded-md"
            style={{
              height: "24px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderLeft: "#606368 1px solid",
              borderTop: "#606368 1px solid",
              borderBottom: "#606368 1px solid"
            }}>
            <div className="relative" style={{ lineHeight: "24px" }}>
              <div
                className={`absolute ${
                  percentLeft > 30
                    ? "bg-green-500"
                    : percentLeft <= 30 && percentLeft > 5
                    ? "bg-yellow-500"
                    : "bg-red-500"
                }`}
                style={{
                  width: `${percentLeft}%`,
                  height: "22px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  top: "1px",
                  left: "0px"
                }}></div>
              <div
                className="z-10"
                style={{ position: "relative", paddingLeft: "8px", paddingRight: "8px" }}>
                {percentSpent}% of credits used
              </div>
            </div>
          </div>
          {subscriptionLevel.includes("blackbaud") ? (
            <button
              onClick={() => addCredits()}
              className="text-white rounded-md bg-fw-med-blue hover:bg-fw-dark-blue"
              style={{
                height: "24px",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                paddingLeft: "8px",
                paddingRight: "8px",
                border: "1px solid #3fa1d7",
                zIndex: 20
              }}>
              Add Credits
            </button>
          ) : (
            <button
              onClick={() => upgradePlan()}
              className="text-white rounded-md bg-fw-med-blue hover:bg-fw-dark-blue"
              style={{
                height: "24px",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                paddingLeft: "8px",
                paddingRight: "8px",
                border: "1px solid #3fa1d7",
                zIndex: 20
              }}>
              Get more credits
            </button>
          )}
        </div>
      ) : null}
      {(subscriptionLevel === "beta" ||
        subscriptionLevel === "none" ||
        subscriptionLevel === "canceled" ||
        subscriptionLevel === "trial") && (
        <button
          onClick={() => upgradePlan()}
          className="pl-10 pr-10 rounded-md bg-fw-dark-purple font-semibold text-sm"
          style={{ marginRight: "50px", color: "white", height: "24px", alignSelf: "center" }}>
          Upgrade Plan
        </button>
      )}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <HtmlTooltip title="Support (opens in new window)" placement="left" arrow>
          <div style={{ display: "flex" }}>
            <button
              style={{ marginRight: "10px" }}
              onClick={() => {
                window.open("https://fundwriter.labiknow.com/", "_blank");
              }}>
              <HelpIcon height={"24px"} width={"24px"} color={"#606368"} />
            </button>
          </div>
        </HtmlTooltip>

        <div className="profile-menu-container" ref={menuRef}>
          <HtmlTooltip title="Account" placement="left" arrow>
            <div style={{ display: "flex" }} onClick={handleClick}>
              <button
                id="user-menu"
                className="mr-2"
                style={{
                  background: "rgb(38, 109, 159)",
                  borderRadius: "50%",
                  color: "white",
                  fontSize: "12px",
                  height: "26px",
                  width: "26px",
                  alignSelf: "center",
                  fontWeight: 500
                }}>
                {initials}
              </button>
            </div>
          </HtmlTooltip>
          {profileMenuOpen && (
            <div className="profile-menu" style={{ color: "#606368" }}>
              <div
                className="dd-item-div"
                onClick={() => {
                  setProfileMenuOpen(false);
                  navigate("/profile");
                }}>
                <span className="mr-1" style={{}}>
                  <ProfileIcon height={"20px"} color={"#606368"} />
                </span>
                <span>Account</span>
              </div>
              <div
                className="dd-item-div"
                onClick={() => {
                  setProfileMenuOpen(false);
                  localStorage.removeItem("user");
                  localStorage.removeItem("authToken");
                  dispatch(logout());
                  navigate("/");
                }}>
                <span className="mr-1" style={{}}>
                  <LogoutIcon height={"15px"} color={"#606368"} />
                </span>
                <span>Logout</span>
              </div>
              {user && user.client && user.clients && user.clients.length > 1 && (
                <div className="flex flex-col">
                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      marginTop: "5px",
                      marginBottom: "5px"
                    }}
                  />
                  <div style={{ cursor: "default", marginBottom: "5px" }}>Select Client</div>
                  {user.clients.map((client: any) => {
                    if (user.client) {
                      const isSelected = client._id === user?.client._id;
                      return (
                        <div
                          className={`client-item flex ${isSelected ? "selected-client" : ""}`}
                          style={{ alignItems: "left !important" }}
                          key={client._id}
                          onClick={async () => {
                            const url = process.env["REACT_APP_API_SERVER"] + "/api/switchClient";
                            const data = { client: client._id };
                            const response: any = await axiosInstance
                              .post(url, data, {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem("authToken")}`
                                }
                              })
                              .then(async (result) => {
                                await dispatch(updateClient(client));
                                const user = localStorage.getItem("user");
                                const jsonUser = user ? JSON.parse(user) : null;
                                if (jsonUser) {
                                  jsonUser.client = client;
                                  localStorage.setItem("user", JSON.stringify(jsonUser));
                                }
                                await dispatch(fetchProjects());
                                await dispatch(fetchUserRecents());
                                navigate("/");
                                setProfileMenuOpen(false);
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                            // localStorage.setItem("selectedClient", JSON.stringify(client));
                          }}>
                          <span className="mr-1" style={{}}>
                            <ProfileIcon height={"20px"} color={"#606368"} />
                          </span>
                          <span>{client.name.split("_client")[0]}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              {user && user.role === "admin" && (
                <div
                  onClick={() => {
                    setProfileMenuOpen(false);
                    const url = new URL("/admin/modelEditor", window.location.origin);
                    window.open(url.href, "_blank");
                  }}>
                  <span className="mr-1" style={{}}>
                    <ExitToApp fontSize="small" />
                  </span>
                  <span>Admin</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
